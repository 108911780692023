@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  #articleContent h1 {
    @apply font-bold pt-5 pb-2 text-4xl;
  }

  #articleContent h2 {
    @apply font-bold pt-5 pb-2 text-3xl;
  }

  #articleContent h3 {
    @apply font-bold pt-5 pb-2 text-2xl;
  }

  #articleContent h4 {
    @apply font-bold pt-5 pb-2 text-xl;
  }

  #articleContent pre {
    @apply dark:outline dark:outline-1 dark:outline-slate-600;
  }

  #articleContent p img {
    @apply my-5 rounded-xl shadow-lg hover:shadow-xl transition ease-in-out;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #articleContent ul {
    @apply list-disc pl-5;
  }

  #articleContent ol {
    @apply list-decimal pl-5;
  }

  #articleContent blockquote {
    @apply p-4 my-4 bg-gray-200 border-l-4 border-gray-300;
  }

  #articleContent table {
    @apply table table-zebra-zebra;
  }

  pre {
    @apply p-[1rem] rounded-lg selection:bg-[#3e4451];
  }

  *::selection {
    @apply bg-primary bg-opacity-40;
  }
}

.swiper-wrapper {
  align-items: center;
}
